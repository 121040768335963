import { inject, Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs';

import { PENDO } from './pendo.factory';
import { PendoOptions } from './pendo-options.type';
import { PendoGenericOption } from './pendo-generic-option.type';
import { PendoAccountData } from './pendo-account-data.type';

@Injectable({ providedIn: 'root' })
export class PendoService {
  private readonly pendo = inject(PENDO);
  private readonly router = inject(Router);

  initialize(
    { tenantId, orgName, userName, id, ssoRole, productVersion }: PendoAccountData,
    extraAccountProps?: PendoGenericOption,
    extraVisitorProps?: PendoGenericOption,
  ): void {
    if (!isDevMode()) {
      const environmentsTypesSuffixesMap = new Map([
        ['app.ciphr.com', ''],
        ['dev.ciphr.com', '_dev'],
        ['prvw.ciphr.com', '_prvw'],
        ['qa.ciphr.com', '_qa'],
      ]);
      const environmentDomain = Array.from(environmentsTypesSuffixesMap.keys()).find((domain) => window.location.host.includes(domain));
      const environmentTypeSuffix = environmentDomain ? environmentsTypesSuffixesMap.get(environmentDomain) : '_other';

      const pendoOptions: PendoOptions = {
        account: {
          id: tenantId + environmentTypeSuffix,
          name: orgName,
          productVersion: productVersion,
          ...extraAccountProps,
        },
        visitor: {
          email: userName,
          id,
          ssoRole: ssoRole.toUpperCase(),
          ...extraVisitorProps,
        },
      };

      this.pendo?.initialize(pendoOptions);
      this.pendo?.startSendingEvents();
      this.trackPageLoads();
    }
  }

  clearSession(): void {
    this.pendo?.clearSession();
    this.pendo?.stopSendingEvents();
  }

  private trackPageLoads(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => this.pendo?.pageLoad());
  }
}
